.root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.subscriptionConditionalStack:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
}
.freeBox___7Tdhq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: var(--token-omGhEM8OI) var(--token-TCakqYrfy);
}
@media (min-width: 600px) {
  .freeBox___7Tdhq {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (min-width: 1024px) {
  .freeBox___7Tdhq {
    padding-left: var(--token-cTiaoKBgu);
    padding-right: var(--token-cTiaoKBgu);
  }
}
@media (min-width: 1280px) {
  .freeBoxshrinkCta___7TdhqX9CMp {
    width: 100%;
    height: 100%;
    max-width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 0;
    min-height: 0;
    padding: var(--token-omGhEM8OI) var(--token-cTiaoKBgu);
  }
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-direction: row;
  box-shadow: 0px 4px 20px 0px #9fb2cc99;
  background: var(--token-D1V5iINMo);
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  border-radius: 12px;
  padding: var(--token-cTiaoKBgu) var(--token-TCakqYrfy);
}
.columns > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - var(--token-u8uQ-APga));
  width: calc(100% + var(--token-u8uQ-APga));
  --plsmc-rc-col-gap: var(--token-u8uQ-APga);
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.columns > :global(.__wab_flex-container) > *,
.columns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns > :global(.__wab_flex-container) > picture > img,
.columns
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-u8uQ-APga);
  margin-top: 12px;
}
.columns > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  .columns {
    max-width: 480px;
    background: var(--token-D1V5iINMo);
    padding: var(--token-cTiaoKBgu) var(--token-JIfrimaWtNy);
  }
}
@media (min-width: 600px) {
  .columns > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
@media (min-width: 900px) {
  .columns {
    flex-direction: row;
  }
}
@media (min-width: 900px) {
  .columns > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 900px) {
  .columns > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
@media (min-width: 1024px) {
  .columns {
    flex-direction: row;
    max-width: none;
    overflow: hidden;
    height: 200px;
    flex-shrink: 0;
    padding: 0px;
  }
}
@media (min-width: 1024px) {
  .columns > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    --plsmc-rc-col-gap: 0px;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1024px) {
  .columns > :global(.__wab_flex-container) > *,
  .columns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns > :global(.__wab_flex-container) > picture > img,
  .columns
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  .columns > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 8 / 12);
  }
}
@media (min-width: 1024px) {
  .columns > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
  }
}
@media (min-width: 1280px) {
  .columns {
    flex-direction: row;
    max-width: 1232px;
  }
}
@media (min-width: 1280px) {
  .columns > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1280px) {
  .columns > :global(.__wab_flex-container) > *,
  .columns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns > :global(.__wab_flex-container) > picture > img,
  .columns
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 1280px) {
  .columns > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 9 / 12);
  }
}
@media (min-width: 1280px) {
  .columns > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
  }
}
@media (min-width: 1024px) {
  .columnsshrinkCta {
    flex-direction: row;
    width: 100%;
    height: auto;
    max-width: 480px;
    box-shadow: 0px 4px 12px 0px var(--token-W8LXJxZWP);
    min-width: 0;
    border-radius: 12px;
    padding: var(--token-cTiaoKBgu) var(--token-JIfrimaWtNy);
  }
}
@media (min-width: 1024px) {
  .columnsshrinkCta > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    min-width: 0;
    margin-left: calc(0px - var(--token-u8uQ-APga));
    width: calc(100% + var(--token-u8uQ-APga));
    --plsmc-rc-col-gap: var(--token-u8uQ-APga);
    margin-top: calc(0px - 12px);
    height: calc(100% + 12px);
  }
}
@media (min-width: 1024px) {
  .columnsshrinkCta > :global(.__wab_flex-container) > *,
  .columnsshrinkCta > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columnsshrinkCta > :global(.__wab_flex-container) > picture > img,
  .columnsshrinkCta
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-u8uQ-APga);
    margin-top: 12px;
  }
}
@media (min-width: 1024px) {
  .columnsshrinkCta > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
@media (min-width: 1280px) {
  .columnsshrinkCta {
    width: 100%;
    height: auto;
    max-width: 1232px;
    box-shadow: 0px 4px 12px 0px var(--token-W8LXJxZWP);
    flex-direction: row;
    min-width: 0;
    border-radius: 12px;
    padding: 0px;
  }
}
@media (min-width: 1280px) {
  .columnsshrinkCta > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    --plsmc-rc-col-gap: 0px;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1280px) {
  .columnsshrinkCta > :global(.__wab_flex-container) > *,
  .columnsshrinkCta > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columnsshrinkCta > :global(.__wab_flex-container) > picture > img,
  .columnsshrinkCta
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
@media (min-width: 1280px) {
  .columnsshrinkCta > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 8 / 12);
  }
}
@media (min-width: 1280px) {
  .columnsshrinkCta > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
  }
}
@media (min-width: 1536px) {
  .columnsshrinkCta {
    width: 100%;
    height: auto;
    box-shadow: 0px 4px 12px 0px var(--token-W8LXJxZWP);
    flex-direction: row;
    min-width: 0;
    border-radius: 12px;
    padding: 0px;
  }
}
@media (min-width: 1536px) {
  .columnsshrinkCta > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    --plsmc-rc-col-gap: 0px;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1536px) {
  .columnsshrinkCta > :global(.__wab_flex-container) > *,
  .columnsshrinkCta > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columnsshrinkCta > :global(.__wab_flex-container) > picture > img,
  .columnsshrinkCta
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
@media (min-width: 1536px) {
  .columnsshrinkCta > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 8 / 12);
  }
}
@media (min-width: 1536px) {
  .columnsshrinkCta > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
  }
}
.column__meGcm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column__meGcm > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-u8uQ-APga));
  height: calc(100% + var(--token-u8uQ-APga));
}
.column__meGcm > :global(.__wab_flex-container) > *,
.column__meGcm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__meGcm > :global(.__wab_flex-container) > picture > img,
.column__meGcm
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-u8uQ-APga);
}
@media (min-width: 1280px) {
  .columnshrinkCta__meGcmX9CMp {
    width: 100%;
    height: 100%;
    max-width: 100%;
    flex-direction: row-reverse;
    min-width: 0;
    min-height: 0;
    padding: 0px;
  }
}
@media (min-width: 1280px) {
  .columnshrinkCta__meGcmX9CMp > :global(.__wab_flex-container) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: stretch;
    min-width: 0;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1280px) {
  .columnshrinkCta__meGcmX9CMp > :global(.__wab_flex-container) > *,
  .columnshrinkCta__meGcmX9CMp
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .columnshrinkCta__meGcmX9CMp > :global(.__wab_flex-container) > picture > img,
  .columnshrinkCta__meGcmX9CMp
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.freeBox__qPGoE {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__qPGoE > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.freeBox__qPGoE > :global(.__wab_flex-container) > *,
.freeBox__qPGoE > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qPGoE > :global(.__wab_flex-container) > picture > img,
.freeBox__qPGoE
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
@media (min-width: 1024px) {
  .freeBox__qPGoE {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 1024px) {
  .freeBox__qPGoE > :global(.__wab_flex-container) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    min-height: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1024px) {
  .freeBox__qPGoE > :global(.__wab_flex-container) > *,
  .freeBox__qPGoE > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__qPGoE > :global(.__wab_flex-container) > picture > img,
  .freeBox__qPGoE
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 1280px) {
  .freeBox__qPGoE {
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 1280px) {
  .freeBox__qPGoE > :global(.__wab_flex-container) {
    min-height: 0;
  }
}
@media (min-width: 1024px) {
  .freeBoxshrinkCta__qPGoEx9CMp {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 1024px) {
  .freeBoxshrinkCta__qPGoEx9CMp > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 1024px) {
  .freeBoxshrinkCta__qPGoEx9CMp > :global(.__wab_flex-container) > *,
  .freeBoxshrinkCta__qPGoEx9CMp
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .freeBoxshrinkCta__qPGoEx9CMp
    > :global(.__wab_flex-container)
    > picture
    > img,
  .freeBoxshrinkCta__qPGoEx9CMp
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
@media (min-width: 1280px) {
  .freeBoxshrinkCta__qPGoEx9CMp {
    width: 100%;
    height: 100%;
    max-width: 100%;
    flex-direction: row-reverse;
    min-width: 0;
    min-height: 0;
    padding: 0px;
  }
}
@media (min-width: 1280px) {
  .freeBoxshrinkCta__qPGoEx9CMp > :global(.__wab_flex-container) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: stretch;
    min-width: 0;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1280px) {
  .freeBoxshrinkCta__qPGoEx9CMp > :global(.__wab_flex-container) > *,
  .freeBoxshrinkCta__qPGoEx9CMp
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .freeBoxshrinkCta__qPGoEx9CMp
    > :global(.__wab_flex-container)
    > picture
    > img,
  .freeBoxshrinkCta__qPGoEx9CMp
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
@media (min-width: 1536px) {
  .freeBoxshrinkCta__qPGoEx9CMp {
    width: 100%;
    height: 100%;
    max-width: 100%;
    flex-direction: row-reverse;
    min-width: 0;
    min-height: 0;
    padding: 0px;
  }
}
@media (min-width: 1536px) {
  .freeBoxshrinkCta__qPGoEx9CMp > :global(.__wab_flex-container) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: stretch;
    min-width: 0;
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1536px) {
  .freeBoxshrinkCta__qPGoEx9CMp > :global(.__wab_flex-container) > *,
  .freeBoxshrinkCta__qPGoEx9CMp
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .freeBoxshrinkCta__qPGoEx9CMp
    > :global(.__wab_flex-container)
    > picture
    > img,
  .freeBoxshrinkCta__qPGoEx9CMp
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.freeBox__afm5Y {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 1024px) {
  .freeBox__afm5Y {
    height: auto;
    justify-content: space-between;
    padding: var(--token-cTiaoKBgu) var(--token-TCakqYrfy);
  }
}
@media (min-width: 1280px) {
  .freeBox__afm5Y {
    padding-left: var(--token-k8o5cN9Xy);
    padding-right: 0px;
    align-items: flex-start;
  }
}
@media (min-width: 1024px) {
  .freeBoxshrinkCta__afm5YX9CMp {
    height: auto;
  }
}
@media (min-width: 1280px) {
  .freeBoxshrinkCta__afm5YX9CMp {
    width: 100%;
    height: 100%;
    max-width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 0;
    min-height: 0;
    padding: var(--token-cTiaoKBgu) var(--token-TCakqYrfy);
  }
}
@media (min-width: 1536px) {
  .freeBoxshrinkCta__afm5YX9CMp {
    width: 100%;
    height: 100%;
    max-width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 0;
    min-height: 0;
    padding: var(--token-cTiaoKBgu) 0px var(--token-cTiaoKBgu)
      var(--token-k8o5cN9Xy);
  }
}
.text__x4TNf {
  color: var(--token-aLKgcASGV);
  font-family: var(--token-x6dXhym8c);
  font-weight: 700;
  font-size: var(--token-qaV8obD4f);
  line-height: var(--token-wmteqkiSA);
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  min-width: 0;
}
@media (min-width: 1024px) {
  .text__x4TNf {
    color: var(--token-aLKgcASGV);
    font-family: var(--token-x6dXhym8c);
    font-weight: 700;
    font-size: var(--token-5NIcBWGHbTC);
    line-height: var(--token-wmteqkiSA);
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .textshrinkCta__x4TNfX9CMp {
    width: 100%;
    height: auto;
    max-width: 800px;
    font-family: var(--token-x6dXhym8c);
    font-size: var(--token-qaV8obD4f);
    font-weight: 700;
    color: var(--token-aLKgcASGV);
    text-align: center;
    line-height: var(--token-wmteqkiSA);
    min-width: 0;
  }
}
@media (min-width: 1280px) {
  .textshrinkCta__x4TNfX9CMp {
    width: 100%;
    height: auto;
    max-width: 800px;
    font-family: var(--token-x6dXhym8c);
    font-size: var(--token-5NIcBWGHbTC);
    font-weight: 700;
    color: var(--token-aLKgcASGV);
    text-align: left;
    line-height: var(--token-wmteqkiSA);
    min-width: 0;
  }
}
@media (min-width: 1536px) {
  .textshrinkCta__x4TNfX9CMp {
    text-align: left;
    width: 100%;
    height: auto;
    max-width: 800px;
    font-family: var(--token-x6dXhym8c);
    font-size: var(--token-5NIcBWGHbTC);
    font-weight: 700;
    color: var(--token-aLKgcASGV);
    line-height: var(--token-wmteqkiSA);
    min-width: 0;
  }
}
.ctaCurrency__ju7J:global(.__wab_instance) {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-Hn0tANBmC);
  font-weight: 400;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
@media (min-width: 600px) {
  .ctaCurrency__ju7J:global(.__wab_instance) {
    text-align: left;
    align-self: flex-start;
  }
}
@media (min-width: 1024px) {
  .ctaCurrency__ju7J:global(.__wab_instance) {
    display: flex;
  }
}
.ctaCurrency__fm1Ny:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
@media (min-width: 1280px) {
  .ctaCurrencyshrinkCta__fm1NyX9CMp:global(.__wab_instance) {
    color: var(--token-KSYikVdQL);
    font-family: var(--token-Hn0tANBmC);
    font-weight: 400;
    font-size: var(--token-jFlfsKM8_wXg);
    line-height: var(--token-MHxx4_Rh4);
    display: flex;
  }
}
.freeBox__inhJ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 1024px) {
  .freeBox__inhJ {
    flex-basis: 220px;
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 1280px) {
  .freeBox__inhJ {
    display: flex;
    flex-direction: row;
    width: auto;
    height: 100%;
    align-self: flex-start;
    flex-basis: 340px;
    min-height: 0;
  }
}
@media (min-width: 1024px) {
  .freeBoxshrinkCta__inhJX9CMp {
    height: auto;
  }
}
@media (min-width: 1280px) {
  .freeBoxshrinkCta__inhJX9CMp {
    height: 100%;
    flex-basis: 220px;
    min-height: 0;
  }
}
@media (min-width: 1536px) {
  .freeBoxshrinkCta__inhJX9CMp {
    width: 100%;
    height: 100%;
    max-width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-basis: 340px;
    min-width: 0;
    min-height: 0;
    padding: 0px;
  }
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-position: center center;
  min-width: 0;
  min-height: 0;
}
.img > picture > img {
  object-fit: cover;
  object-position: center center;
}
@media (min-width: 1024px) {
  .img {
    object-position: right center;
  }
}
@media (min-width: 1024px) {
  .img > picture > img {
    object-position: right center;
  }
}
@media (min-width: 1024px) {
  .imgshrinkCta {
    object-position: center center;
  }
}
@media (min-width: 1024px) {
  .imgshrinkCta > picture > img {
    object-position: center center;
  }
}
@media (min-width: 1280px) {
  .imgshrinkCta {
    object-position: right center;
  }
}
@media (min-width: 1280px) {
  .imgshrinkCta > picture > img {
    object-position: right center;
  }
}
.column___8OOfL {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 1024px) {
  .column___8OOfL {
    align-items: center;
    justify-content: flex-start;
    padding-top: var(--token-TCakqYrfy);
    padding-bottom: var(--token-TCakqYrfy);
  }
}
@media (min-width: 1536px) {
  .columnshrinkCta___8OOfLx9CMp {
    width: 100%;
    height: auto;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 0;
    padding: var(--token-TCakqYrfy) 0px;
  }
}
.text___6D7 {
  color: var(--token-aLKgcASGV);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 600;
  font-size: var(--token-XMoXRN_s63-);
  line-height: var(--token-MHxx4_Rh4);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  min-width: 0;
}
@media (min-width: 1024px) {
  .text___6D7 {
    display: none;
  }
}
@media (min-width: 1024px) {
  .textshrinkCta___6D7X9CMp {
    display: block;
  }
}
@media (min-width: 1280px) {
  .textshrinkCta___6D7X9CMp {
    display: none;
  }
}
@media (min-width: 1536px) {
  .textshrinkCta___6D7X9CMp {
    display: none;
  }
}
.freeBox__n7YxI {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__n7YxI > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - var(--token-rYh-ACW-A));
  width: calc(100% + var(--token-rYh-ACW-A));
}
.freeBox__n7YxI > :global(.__wab_flex-container) > *,
.freeBox__n7YxI > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__n7YxI > :global(.__wab_flex-container) > picture > img,
.freeBox__n7YxI
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-rYh-ACW-A);
}
.text__uNvUx {
  color: var(--token-0ZyY30VTY);
  font-family: var(--token-Hn0tANBmC);
  font-weight: 600;
  font-size: var(--token--qjKwCUH1);
  line-height: var(--token-MHxx4_Rh4);
  width: auto;
  height: auto;
}
@media (min-width: 900px) {
  .text__uNvUx {
    color: var(--token-KSYikVdQL);
    font-family: var(--token-Hn0tANBmC);
    font-weight: 600;
    font-size: var(--token-XMoXRN_s63-);
    line-height: var(--token-MHxx4_Rh4);
  }
}
@media (min-width: 1280px) {
  .textshrinkCta__uNvUxx9CMp {
    width: auto;
    height: auto;
    font-family: var(--token-Hn0tANBmC);
    font-size: var(--token-XMoXRN_s63-);
    font-weight: 600;
    color: var(--token-KSYikVdQL);
    line-height: var(--token-MHxx4_Rh4);
  }
}
.ctaPrice:global(.__wab_instance) {
  color: var(--token-0ZyY30VTY);
  font-family: var(--token-x6dXhym8c);
  font-weight: 700;
  font-size: var(--token-5NIcBWGHbTC);
  line-height: var(--token-MHxx4_Rh4);
  max-width: 100%;
  object-fit: cover;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
@media (min-width: 1024px) {
  .ctaPrice:global(.__wab_instance) {
    font-size: var(--token-I-eWu6aAAvb);
  }
}
@media (min-width: 1024px) {
  .ctaPriceshrinkCta:global(.__wab_instance) {
    max-width: 100%;
    font-family: var(--token-x6dXhym8c);
    font-size: var(--token-5NIcBWGHbTC);
    font-weight: 700;
    color: var(--token-0ZyY30VTY);
    line-height: var(--token-MHxx4_Rh4);
    flex-direction: row;
    align-items: baseline;
  }
}
@media (min-width: 1280px) {
  .ctaPriceshrinkCta:global(.__wab_instance) {
    max-width: 100%;
    font-family: var(--token-x6dXhym8c);
    font-size: var(--token-I-eWu6aAAvb);
    font-weight: 700;
    color: var(--token-0ZyY30VTY);
    line-height: var(--token-MHxx4_Rh4);
    flex-direction: row;
    align-items: baseline;
  }
}
@media (min-width: 1536px) {
  .ctaPriceshrinkCta:global(.__wab_instance) {
    max-width: 100%;
    font-family: var(--token-x6dXhym8c);
    font-size: var(--token-I-eWu6aAAvb);
    font-weight: 700;
    color: var(--token-0ZyY30VTY);
    line-height: var(--token-MHxx4_Rh4);
    flex-direction: row;
    align-items: baseline;
  }
}
.text__fNsn {
  color: var(--token-aLKgcASGV);
  font-family: var(--token-Hn0tANBmC);
  font-weight: 400;
  font-size: var(--token-DckCCvtwain);
  line-height: var(--token-MHxx4_Rh4);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  padding-bottom: 12px;
  min-width: 0;
}
@media (min-width: 1024px) {
  .text__fNsn {
    padding-bottom: var(--token-TCakqYrfy);
    margin-bottom: 13px;
  }
}
@media (min-width: 1536px) {
  .textshrinkCta__fNsnX9CMp {
    width: 100%;
    height: auto;
    max-width: 800px;
    padding-bottom: var(--token-TCakqYrfy);
    font-family: var(--token-Hn0tANBmC);
    font-size: var(--token-DckCCvtwain);
    font-weight: 400;
    color: var(--token-aLKgcASGV);
    text-align: center;
    line-height: var(--token-MHxx4_Rh4);
    min-width: 0;
  }
}
.planCtaClicked:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.emailPlanCtaClicked:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.link {
  position: relative;
  color: var(--token-D1V5iINMo);
  background: var(--token-TDUv9IlsPLe);
  width: 100%;
  font-size: var(--token-XMoXRN_s63-);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 600;
  margin-bottom: var(--token-u8uQ-APga);
  text-align: center;
  min-width: 0;
  display: none;
  border-radius: 8px;
  padding: 12px 28px;
  border: 1px solid var(--token-8j5e4W9rawI);
}
@media (min-width: 1024px) {
  .link {
    width: auto;
    min-width: 80%;
  }
}
.root .link:hover {
  color: var(--token-D1V5iINMo);
  background: var(--token-hUFPDfh1_);
  border-color: var(--token-1T1xhlQ_M);
}
.ctaButton:global(.__wab_instance) {
  object-fit: cover;
  color: var(--token-D1V5iINMo);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 600;
  font-size: var(--token-XMoXRN_s63-);
  text-align: center;
  width: 100%;
  background: var(--token-TDUv9IlsPLe);
  margin-bottom: 8px;
  min-width: 0;
  border-radius: 8px;
  padding: 12px 28px;
  border: 1px solid var(--token-8j5e4W9rawI);
}
@media (min-width: 1024px) {
  .ctaButton:global(.__wab_instance) {
    margin: 0px 0px 8px;
  }
}
@media (min-width: 1280px) {
  .ctaButton:global(.__wab_instance) {
    margin: 0px 0px 8px;
  }
}
.text__os6L {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-Hn0tANBmC);
  font-weight: 400;
  font-size: var(--token-ufBEFU4Jv);
  line-height: var(--token-MHxx4_Rh4);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  padding-bottom: 0px;
  min-width: 0;
}
@media (min-width: 1024px) {
  .text__os6L {
    display: none;
  }
}
@media (min-width: 1024px) {
  .textshrinkCta__os6LX9CMp {
    display: block;
  }
}
@media (min-width: 1280px) {
  .textshrinkCta__os6LX9CMp {
    display: none;
  }
}
@media (min-width: 1536px) {
  .textshrinkCta__os6LX9CMp {
    display: none;
  }
}
.ctaCurrency__pjoIg:global(.__wab_instance) {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-Hn0tANBmC);
  font-weight: 400;
  font-size: var(--token-ufBEFU4Jv);
  line-height: var(--token-MHxx4_Rh4);
  max-width: 100%;
  object-fit: cover;
  position: relative;
  text-align: center;
  margin-top: 20px;
}
